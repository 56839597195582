import { Button, Modal } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";



export default function Card({qrCode, info, history}) {

    let [dload,setDload] = useState();

    const parseValue = (value) => {
        return parseInt(value, 10);
    }

    const saveCache = () => {
      const newHistory = history;
      // 轮询历史查看是否有相同姓名的缓存 ? 替换 : 新增
      const index = newHistory.findIndex(item => item?.name === info?.name);
      if (index !== -1) {
        newHistory[index] = info;   // 覆盖
      }else{
        newHistory.push(info);  // push
      }
      let local = JSON.parse(localStorage.getItem("vcard-info"));
      local.datasheets = newHistory;
      localStorage.setItem("vcard-info", JSON.stringify(local));
    }
    
    const download = async() => {
        saveCache();
        // 获取当前可视高度 < 425 => 临时修改内容大小
        if (window.innerWidth < 425) {
          const dom = document.querySelector(".down");
          dom.classList.remove("down-mobile");
        }
        Modal.destroyAll()
        // 获取想要转换的 DOM 节点
        const dom = document.getElementById('canvans');
        const box = window.getComputedStyle(dom);
        // DOM 节点计算后宽高
        const width = parseValue(box.width);
        const height = parseValue(box.height);
        if (box.transform !== "none") {
          dom.style.transform = "none";
        }
        // 获取像素比-防止模糊
        const scaleBy = 1;
        // 创建自定义 canvas 元素
        const canvas = document.createElement('canvas');
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        // 获取画笔
        const context = canvas.getContext('2d');

        // 将所有绘制内容放大像素比倍
        context.scale(scaleBy, scaleBy);

        // 将自定义 canvas 作为配置项传入，开始绘制
        return await html2canvas(dom, { width: 675, height: 408, scrollX: 0, scrollY: 0, scale: 5 }).then(canvas => {
            //document.querySelector("#canvasContainer").appendChild(canvas);
            //return canvas.toDataURL("image/png");
            dload = canvas.toDataURL("image/png");
            setDload(dload);

            if (window.navigator.msSaveOrOpenBlob) {
              var bstr = atob(dload.split(',')[1])
              var n = bstr.length
              var u8arr = new Uint8Array(n)
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
              }
              var blob = new Blob([u8arr])
              window.navigator.msSaveOrOpenBlob(blob, `vCard_${info?.name}` + '.' + 'pdf')
            } else {
              // 这里就按照chrome等新版浏览器来处理
              const a = document.createElement('a')
              a.href = dload
              a.setAttribute('download', `vCard_${info?.name}`)
              a.click()
            }
            
            var pdf = new jsPDF('l', 'cm', [9.701, 6.098]);
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0.3, 0.3, 9.1, 5.5);
            pdf.save(`vCard_${info?.name}.pdf`);
            return canvas.toDataURL("image/png");
        });
    }
    
    return (
        <div className='box'>
            <div className="shadow">
              <div id="canvans">
                <img id="_bg" src={require("../../assets/bg.png")} alt="" />
                { qrCode && <img src={qrCode} className='img' alt="" /> }
                <div className="logo">
                    {
                        info?.logo ? 
                        <img src={info.logo} alt="" />:
                        <p>Logo</p>
                    }
                </div>
                <p className={`name ${info?.name?.length > 11 ? 'middle':''}`}>{info?.name || "Name"}&nbsp;&nbsp;<span>{info?.katakana}</span></p>
                <p className="job">{info?.title || "Job Position"}</p>
                <p className="email text">{info?.email || "email"}</p>
                <p className="phone text">{info?.prefix || "Telephone Number"}&nbsp;{info?.phone}</p>
                <p className="address text">{info?.addr || "address"}</p>
                <p className="corp text">{info?.corp || "ハッピーハウジング 株式会社"}</p>
                <p className="url text">{info?.url || "Company address"}</p>
                <img className="custom-icon" id="icon-tel" src={require("../../assets/icon-tel.png")} alt="" />
                <img className="custom-icon" id="icon-email" src={require("../../assets/icon-email.png")} alt="" />
                <img className="custom-icon" id="icon-name" src={require("../../assets/icon-name.png")} alt="" />
                <img className="custom-icon" id="icon-addr" src={require("../../assets/icon-addr.png")} alt="" />
                <img className="custom-icon" id="icon-url" src={require("../../assets/icon-url.png")} alt="" />
              </div>
            </div>
            <Button className='download' onClick={() => download()}><img src={require("../../assets/icon2.png")} alt="" style={{width: '24px', height: '24px'}} /> Download</Button>
      </div>
    )
}