import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, Form, Input, Select, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { getBase64 } from "../../utils/getBase64";
import "../../style/generate.scss";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 },
  },
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 90,
      }}
    >
      <Option value="+81">+81</Option>
      <Option value="+86">+86</Option>
      <Option value="+87">+87</Option>
      <Option value="+89">+89</Option>
      <Option value="+866">+866</Option>
    </Select>
  </Form.Item>
);

function GenerateForm({
    onChange, 
    initialValues,
}, ref) {

  const [form] = Form.useForm();
  let [imageUrl, setImageUrl] = useState(); // 图片url

  // 上传logo格式检测
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  // 上传logo
  const handleChange = async(info) => {
    const values = await form.getFieldsValue();
    getBase64(info.file.originFileObj, (url) => {
      imageUrl = url;
      setImageUrl(imageUrl);
      const local = JSON.parse(localStorage.getItem("vcard-info"));
      local.img = url;
      localStorage.setItem("vcard-info", JSON.stringify(local))
      formChange(values);
    });
  };

  function formChange(values) {
    let obj = JSON.parse(JSON.stringify(values));
    obj.logo = imageUrl;
    onChange(obj);
  }

  function clear(params) {
    form.resetFields();
    setImageUrl(null);
  }

  async function init() {
    form.setFieldsValue(initialValues);
    if (initialValues?.logo) {
      imageUrl = initialValues?.logo;
      setImageUrl(imageUrl);
    }
    onChange(initialValues);
  }

  useImperativeHandle(ref, () => ({
    clear
  }))

  useEffect(() => {
    initialValues && init();
  },[initialValues])

  return (
    <Form
        className="form"
        form={form}
        name="register"
        scrollToFirstError
        initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "+81",
        }}
        {...formItemLayout}
        onValuesChange={(e, values) => {
            formChange(values)
        }}
    >
      {/* 上传LOGO */}
      <Form.Item label="Logo" name="fileList" valuePropName="img">
        <Upload
          name="file"
          multiple={false}
          maxCount={1}
          beforeUpload={beforeUpload}
          className="custom-upload"
          onChange={handleChange}
        >
          <div className="upload-img">
            {
                imageUrl ? 
                <div className="img">
                  <img src={imageUrl} alt="logo" />
                </div>:
                <div className="img-box">Logo</div>
            }
            <div className="img-info">
              <Button>
                <PlusOutlined />
                Upload logo
              </Button>
              <span className="tips">image type：jpg, png. Maximum size: 20 MB.</span>
            </div>
          </div>
        </Upload>
      </Form.Item>

      {/* 姓名 */}
      <Form.Item name="name" label="Your Name">
        <Input placeholder="Your Name" />
      </Form.Item>

      {/* 片假名 */}
      <Form.Item name="katakana" label="Katakana">
        <Input placeholder="Your Katakana" />
      </Form.Item>

      {/* 工作职位 */}
      <Form.Item name="title" label="Job Position">
        <Input placeholder="Your Job Position" />
      </Form.Item>

      {/* 邮箱 */}
      <Form.Item name="email" label="Email">
        <Input placeholder="Your email" />
      </Form.Item>

      {/* 手机 */}
      <Form.Item name="phone" label="Phone">
        <Input
            className="phone"
            addonBefore={prefixSelector}
            style={{width: "100%"}}
            placeholder="Your phone number"
        />
      </Form.Item>

      {/* 地址 */}
      <Form.Item name="addr" label="Addr">
        <Input placeholder="Xxx Building, Xxx St" />
      </Form.Item>

      {/* 公司名称 */}
      <Form.Item name="corp" label="Corp">
        <Input placeholder="Please enter the company name" />
      </Form.Item>

      {/* 公司官网 */}
      <Form.Item name="url" label="URL">
        <Input placeholder="Please enter the website address" />
      </Form.Item>

      {/* 邀请码 */}
      <Form.Item name="code" label="Invitation code">
        <Input placeholder="Invitation code" />
      </Form.Item>
    </Form>
  );
}

export default forwardRef(GenerateForm)