import { useEffect, useRef, useState } from "react";
import { Button, Modal, Select } from "antd";
import araleQrcode from "arale-qrcode";
import GenerateForm from "./form";
import Card from "./card";
import md5 from "md5";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;


export default function Generate(params) {
    
    const form = useRef(null);
    const navigate = useNavigate();
    const [qrCode, setQrCode] = useState();
    let [info, setInfo] = useState();
    let [initialValues, setInitialValues] = useState();
    let [history, setHistory] = useState([]);
    let [option, setOption] = useState([]);
    let [selectOption, setSelectOption] = useState();

    function clear() {
        form.current.clear();
        setInfo(null);
        setSelectOption(null);
    }

    function formChange(values) {
        createQRCode(values);
        info = values;
        setInfo({...info});
    }

    // 创建卡片 => 弹窗
    function createCard(params) {
        confirm({
            content: <Card qrCode={qrCode} info={info} history={history} />,
            maskClosable: true,
            className: 'down down-mobile',
            icon: '',
            okButtonProps: {style: {display: 'none'}},
            cancelButtonProps: {style: {display: 'none'}},
            bodyStyle: {
              backgroundColor: 'rgba(255, 255, 255, 0) !important',
            }
        });
    }

    // 生成二维码
    const createQRCode = ({name, title, email, prefix, phone, code, corp, url, addr}) => {
        let newUrl;
        let vCardText = 'BEGIN:VCARD\n';
        if (url) {
            newUrl = url.replace(/https:\/\/|http:\/\/|www\./gi, "");
        }
        vCardText += 'VERSION:3.0\n';
        vCardText += `N:${name||""}; ;;\n`;
        vCardText += `FN:${name||""}\n`;
        vCardText += `ORG:${corp||""}\n`;
        vCardText += `TITLE:${title||""}\n`;
        vCardText += `TEL;TYPE=WORK,VOICE:${prefix||""},${phone||""}\n`;
        vCardText += `EMAIL:${email||""}\n`;
        vCardText += `NOTE:${code||""}\n`;
        vCardText += `ADR;TYPE=WORK:;;${addr||""}\n`; // 工作地址
        vCardText += `URL:${url ? newUrl : ""}\n`; // 网址
        vCardText += 'END:VCARD';
        const result = new araleQrcode({
          render:  "svg",  /*  生成的类型 'svg' or 'table dom元素类型 */
          text: vCardText,
          size: 122,   /* 二维码的大小 */
          correctLevel: 1,
          typeNumber: 10
        })
        const svgXml = new XMLSerializer().serializeToString(result)
  
        const src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svgXml)))
        setQrCode(src);
    }

    // 切换历史数据
    function handleChange(value) {
        try {
            form.current.clear();
            setInfo(null);
            const selectHistory = history.filter(e => e.name === value);
            const local = JSON.parse(localStorage.getItem("vcard-info"));
            initialValues = {
                ...selectHistory[0],
                logo: local.img,
                fileList: {
                    uid: '1',
                    name: 'logo.png',
                    status: 'done',
                    url: local.img,
                },
            };
            setInitialValues({...initialValues});
            selectOption = value;
            setSelectOption(selectOption);  
        } catch (error) {
            console.log(error);
        }
    }

    // 初始化本地缓存
    function cacheInit() {
        const info = {
            img: "",
            datasheets: []
        }
        localStorage.setItem("vcard-info", JSON.stringify(info));
    }

    // 初始化local
    function init(values) {
        // 判断是否为旧版缓存
        const index = Object.keys(values).findIndex(e => e === "datasheets");
        if (index === -1) {
            cacheInit();
            return
        }
        initialValues = {
            ...values.datasheets[values.datasheets.length - 1],
            logo: values.img,
            fileList: {
                uid: '1',
                name: 'logo.png',
                status: 'done',
                url: values.img,
            },
        };
        setInitialValues({...initialValues});
        history = values.datasheets;
        setHistory([...history]);
        // init 历史选择器
        option = history.map(e => {
            return {
                label: e.name,
                value: e.name
            }
        });
        setOption([...option]);
        selectOption = initialValues.name;
        setSelectOption(selectOption);
    }

    useEffect(() => {
        const pwd = localStorage.getItem("vcard-pwd");
        if (!pwd || md5(process.env.REACT_APP_PWD) !== pwd) {
            navigate('/')
        }
        const local = localStorage.getItem("vcard-info");
        local ? init(JSON.parse(local)) : cacheInit();
    },[])

    return (
        <div className="generate">
            <div className="container">
                <div>
                    <div className="inner">
                        <img className='clear' onClick={() => clear()} src={require("../../assets/icon1.png")} alt="" />
                        <p className="title">vCard Generator</p>
                        <div style={{width: "100%", paddingLeft: "40px", marginBottom: "50px"}}>
                            <Select 
                                bordered={false} 
                                style={{minWidth: "170px"}} 
                                onChange={handleChange}
                                value={selectOption}
                                options={option}
                                placeholder="Select users"
                                suffixIcon={<img src={require("../../assets/icon-arrow.png")} style={{width: "12px", height: "12px"}} />}
                            />
                        </div>
                        {/* Form表单 */}
                        <GenerateForm ref={form} onChange={formChange} initialValues={initialValues} />
                    </div>
                    <div className="create">
                        <Button className="btn-create" onClick={createCard}>Create vCard</Button>
                    </div>
                </div>
                <div className="bg">
                    <p className="title">Preview</p>
                    <div className="card">
                        <div className="canvans">
                            { qrCode && <img src={qrCode} className='img' alt="" /> }
                            
                            <div className="logo">
                                {
                                    info?.logo ? 
                                    <img src={info.logo} alt="" />:
                                    <p>Logo</p>
                                }
                            </div>
                            <p className={`name ${info?.name?.length > 11 ? 'middle':''}`}>{info?.name || "Name"}&nbsp;&nbsp;<span>{info?.katakana}</span></p>
                            <p className="job">{info?.title || "Job Position"}</p>
                            <p className="email text">{info?.email || "email"}</p>
                            <p className="phone text">{info?.prefix || "Telephone Number"}&nbsp;{info?.phone}</p>
                            <p className="address text">{info?.addr || "address"}</p>
                            <p className="corp text">{info?.corp || "ハッピーハウジング 株式会社"}</p>
                            <p className="url text">{info?.url || "Company address"}</p>
                            <img className="custom-icon" id="icon-tel" src={require("../../assets/icon-tel.png")} alt="" />
                            <img className="custom-icon" id="icon-email" src={require("../../assets/icon-email.png")} alt="" />
                            <img className="custom-icon" id="icon-name" src={require("../../assets/icon-name.png")} alt="" />
                            <img className="custom-icon" id="icon-addr" src={require("../../assets/icon-addr.png")} alt="" />
                            <img className="custom-icon" id="icon-url" src={require("../../assets/icon-url.png")} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}