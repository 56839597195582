import Generate from "../views/Generate";
import Index from "../views/Index";



export const routes = [
    {
        path:'/generate',
        element: <Generate />
    },
    {
        path:'/',
        element: <Index/>
    }
]