import { Button, Input, message } from "antd";
import { ArrowRightOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import '../style/signIn.scss';
import { useEffect, useState } from "react"; 
import { useNavigate } from 'react-router-dom'
const md5 = require('md5');
export default function Index() {

    const navigate = useNavigate();
    const [isTips, setIsTips] = useState();
    let [pwd,setPwd] = useState('');
    // password

    const submit = () => {
        if (md5(pwd) !== md5(process.env.REACT_APP_PWD)) {
            pwd === "" && setIsTips(true);
            message.error('密码错误')
        }else{
            localStorage.setItem("vcard-pwd", md5(pwd));
            setTimeout(() => {
                navigate('/generate')
            }, 500);
        }
    }

    useEffect(() => {
    },[])

    return(
        <div className="Index">
            <div className="content">
                <p className="title">Welcome to Vcard Generator</p>
                <div className="inner">
                    {/* <input type="text" placeholder="Enter Password" onChange={e => {setPwd(e.target.value)}} /> */}
                    {isTips && <div className="tips">Please enter the password</div>}
                    <Input.Password
                        className="input"
                        placeholder="Enter Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onChange={e => {setPwd(e.target.value)}}
                    />
                    <Button className="btn" onClick={() => submit()}><span className="font">Start</span> <span className="r"><ArrowRightOutlined style={{fontSize: '17px', color: '#006BF9'}} /></span></Button>
                </div>
            </div>
        </div>
    )
}