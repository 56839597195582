import './style/App.css';

import {
  useRoutes
} from 'react-router-dom';

import {routes} from './router/router'
import { useEffect } from 'react';

function App() {
  let element = useRoutes(routes);
  const baseSize = 16;

  function setRemUnit() {
    const clientWidth = document.documentElement.clientWidth;
    if (clientWidth < 490) {
      const scale = document.documentElement.clientWidth / 390;
      document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
    }
  }

  useEffect(() => {
    setRemUnit();
    window.addEventListener('resize', setRemUnit);

    return () => {
      window.removeEventListener('resize', setRemUnit);
    };
  }, []);

  return element;
}

export default App;

